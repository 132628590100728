/*
 * Site-Wide JavaScript Configuration
 */

// typekit
// (function(d){
//     var config = {
//         kitId: 'sau0ebh',
//         scriptTimeout: 3000,
//         async: true
//     },
//     h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
// })(document);

jQuery(document).ready(function($) {
    // foundation --------------------------------------------------------------
    $(document).foundation();

    $(document).ready(function(){
        $('#testimonials').slick({
            autoplay: true,
            autoplaySpeed: 2900
        });
    });

    $('.off-canvas a').on('click', function() {
        $('.off-canvas').foundation('close');
    });

    // wow ---------------------------------------------------------------------
    var wow = new WOW({
        boxClass     : 'wow',      // animated element css class (default is wow)
        animateClass : 'animated', // animation css class (default is animated)
        offset       : 0,          // distance to the element when triggering the animation (default is 0)
        mobile       : true,       // trigger animations on mobile devices (default is true)
        live         : true,       // act on asynchronously loaded content (default is true)
        callback     : function(box) {
            // use for custom behavior after a "box" enters screen
        },
        scrollContainer : null // optional scroll container selector, otherwise use window
    });
    wow.init();

    // lazy --------------------------------------------------------------------
    $('.lazy').lazyload();

    // smooth scroll -----------------------------------------------------------
    $(function() {
        $('a.scroll').click(function() {
            // var headHeight = $('#header').height();
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        //scrollTop : target.offset().top - headHeight
                        scrollTop : target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

    // headroom if we go for a fixed header - uncomment in gulpfile.js if needed
    // var myElement = document.getElementById('header');
    // var headroom  = new Headroom(myElement);
    // headroom.init();
    // headroom.offset = 0;

    // archive infinitescroll
    // $('#archive').infinitescroll({
    //     navSelector  : "#pagination",
    //     nextSelector : "#pagination #next a",
    //     itemSelector : "#archive .excerpt",
    //     loading: {
    //         // finished: undefined,
    //         finishedMsg: '<h4 class="text-center">End of Posts</h4>',
    //         // img: null,
    //         // msg: null,
    //         msgText: '<p class="text-center">loading...</p>',
    //         // selector: null,
    //         // speed: 'fast',
    //         // start: undefined
    //     },
    // });
});
